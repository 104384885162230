@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.StaggeredImagesItem {
  position: relative;
  width: 80%;

  @include bp(tablet) {
    width: 100%;
  }

  [data-image-count='3'] & {
    &:nth-child(1) {
      @include box-styling(roundedCorners);

      @include bp(tablet) {
        grid-column: 1/5;
      }
    }

    &:nth-child(2) {
      @include box-styling(arch);
      align-self: flex-end;

      @include bp(tablet) {
        grid-column: 5/9;
        margin-top: px(208);
      }
    }

    &:nth-child(3) {
      @include box-styling(roundedCorners);

      @include bp(tablet) {
        grid-column: 9/-1;
        margin-top: px(104);
      }
    }
  }

  [data-image-count='2'] & {
    &:nth-child(1) {
      @include box-styling(arch);
      width: 80%;

      @include bp(tablet) {
        width: 40%;
      }
    }

    &:nth-child(2) {
      @include box-styling(roundedCorners);
      width: 80%;
      align-self: flex-end;

      @include bp(tablet) {
        width: 40%;
        margin-top: px(150);
      }
    }
  }
}

.image,
.video {
  @include position-100(absolute);
  object-fit: cover;
}
