@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.ThreeUpBlogPostCard {
}

.link {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.category,
.datePublished {
  @include font-detail-s;
}

.title {
  @include font-sh5;
  margin: px(24) 0 px(40);
}

.buttonContainer {
  width: fit-content;
  margin-top: auto;
}
