@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.ContentCards {
  padding: 0 var(--page-gutter);
}

.inner {
  @include grid;
  max-width: var(--section-max-width);
  margin: 0 auto;
}

.list {
  display: flex;
  flex-direction: column;
  gap: var(--gutter);
  grid-column: 1/-1;

  @include bp(mobile, true) {
    gap: px(80);
  }

  @include bp(mobile) {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }

  @include bp(tablet) {
    grid-column: 2/12;
  }
}

.item {
  width: 100%;
}

.itemInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  height: 100%;
}

.imageContainer {
  @include box-styling(roundedCorners);
  width: 100%;
}

.image,
.video {
  @include position-100(absolute);
  object-fit: cover;
}

.textContent {
  padding: px(40) var(--gutter) 0;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  @include font-sh4;
}

.description {
  @include font-body;
  margin-top: px(24);
}

.ctaContainer {
  padding-top: px(32);
  margin-top: auto;
}

.smallText {
  @include font-body-s;
  margin-top: px(12);
}
