@import "@/styles/shared";
.FormResults {
  padding: px(24) px(0);

  @include bp(tablet) {
    padding: px(12) px(0);
  }
}

.FormResultsError {
  color: var(--error-message-text-color);
}

.FormResultsSuccess {
  color: var(--success-message-text-color);
}


.FormResultsNeutral {
  color: var(--text-color);
}