@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.ContentWithStickyNavigation {
  padding-top: calc(var(--content-with-sticky-nav-button-height) * 2);

  @include bp(laptop) {
    padding-top: 0;
  }
}

.grid {
  position: relative;
  z-index: 2;

  @include bp(laptop) {
    display: grid;
    gap: var(--gutter);
    grid-template-columns: #{px(230)} 1fr;

    .widerNav & {
      grid-template-columns: #{px(300)} 1fr;
      gap: px(85);
    }
  }
}

.navigation {
  position: sticky;
  top: calc(var(--header-height) + #{px(20)});
  left: 0;
  display: none;
  max-height: var(--nav-max-height);
  overflow: hidden;

  --nav-max-height: calc(100svh - calc(calc(var(--header-height) + #{px(20)})));

  &.stickyBannerIsOpen {
    top: calc(var(--header-height) + var(--stickybanner-height) + #{px(20)});
    @include bp(xsmobile, true) {
      top: calc(var(--header-height) + var(--stickybanner-height-xsmobile) + #{px(20)});
    }
  }
  @include bp(laptop) {
    display: block;
  }
}

.navigation__inner {
  position: relative;
  max-height: var(--nav-max-height);
  overflow: auto;
  padding-bottom: px(20);
}

.navigation__item {
  margin-bottom: px(8);
}

.navigation__button {
  @include reset-button;
  border-bottom: 1px solid transparent;
  transition: border-color 0.1s;

  .isActive & {
    border-color: var(--text-color);
  }
}

.navigation__buttonText {
  @include font-button;
}

.navigation__buttonCount {
  @include font-handle;
}
