@import "@/styles/shared";
.EmailSignupInput {
  padding-bottom: px(16);
  border-bottom: 1px solid rgba(var(--fg-rgb), 0.1);

  &[data-variant='simple'] {
    border-bottom: 0;
    min-height: 0;
    padding-bottom: 0;

    @include bp(tablet) {
      padding-bottom: px(10);
    }
  }

  @include bp(tablet) {
    padding-bottom: px(88);
  }
}

.EmailSignupInputSimple {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.formContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: px(32);

  @include bp(tablet) {
    flex-direction: row;
    align-items: center;
    gap: px(10);
  }

  [data-variant='simple'] & {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    border-radius: px(64);
    border: px(1) solid var(--input-border-color);
    padding: px(8) px(8) px(8) px(24);
    gap: px(10);

    &.focused {
      border-color: var(--input-text-color);
    }
  }
}

.inputContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  flex: 1;
  width: 100%;

  [data-variant='simple'] & {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex: 1;
  }
}

.emailInput {
  display: flex;
  flex: 1;
  padding: 0;
  background: transparent;
  border: none;
  color: var(--input-text-color);
  width: 100%;
  @include font-sh2;

  &::placeholder {
    color: rgba(var(--fg-rgb), #{$placeholder-opacity});
  }

  &:focus-visible,
  &:focus {
    outline: none;
  }

  [data-variant='simple'] & {
    @include font-body-xl;
    display: flex;
    flex: 1;
    padding: 0;
    background: transparent;
    border: none;
    color: var(--input-text-color);
    width: 100%;

    &::placeholder {
      color: var(--input-placeholder-color);
    }
  }
}
