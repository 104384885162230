@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.ThreeUpLocationCard {
  @include hover {
    .image {
      transform: scale(1.1);
    }
  }
}

.imageContainer {
  width: 100%;
  position: relative;
  aspect-ratio: 445/509;
  margin-bottom: px(16);
  overflow: hidden;
}

.image {
  @include position-100(absolute);
  transition: transform 0.6s;
  object-fit: cover;
}

.title {
  @include font-body-xl-display;
  margin-bottom: px(4);
}

.openClosedBlurb {
  margin-bottom: px(12);
}

.phoneNumber,
.address {
  @include font-body-s;
}
