@import "@/styles/shared";
.ContactForm {
  padding: 0 var(--page-gutter);
}

.inner {
  @include grid;
  max-width: var(--section-max-width);
  margin: 0 auto;
}

.textContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: px(24);
  grid-column: 1/-1;
  margin-bottom: px(60);

  @include bp(tablet) {
    margin-bottom: 0;
    padding-right: var(--gutter);
    grid-column: 1/7;
  }
}

.formContainer {
  grid-column: 1/-1;

  @include bp(tablet) {
    padding-left: var(--gutter);
    grid-column: 7/-1;
  }
}

.title {
  @include font-h5;
}

.subtitle {
  @include font-detail-s;
  margin-bottom: px(12);
}
