@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.Stats {
  padding: 0 var(--page-gutter);
}

.inner {
  @include grid;
  max-width: var(--section-max-width);
  margin: 0 auto;
  align-items: center;
}

.textSide {
  grid-column: 1/-1;
  margin-top: px(32);
  order: 2;

  @include bp(tablet) {
    order: 1;
    margin-top: 0;
    grid-column: 1/7;
  }
}

.title {
  @include font-h5;
  margin-bottom: px(64);

  @include bp(tablet) {
    margin-bottom: px(112);
  }
}

.items {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  gap: var(--gutter);

  @include bp(mobile) {
    grid-template-columns: repeat(auto-fit, minmax(10%, 1fr));
  }
}

.item__number {
  @include font-h5;
}

.item__text {
  @include font-detail-s;
}

.imageSide {
  grid-column: 1/-1;
  order: 1;

  @include bp(tablet) {
    order: 2;
    grid-column: 7/-1;
  }
}

.imageContainer {
  @include box-styling(roundedCorners);
}

.image {
  @include position-100(absolute);
  object-fit: cover;
}
