@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.MediaBackgroundAndTitle {
  @include dark-theme;
  position: relative;
  color: var(--text-color);
  padding: px(160) var(--page-gutter) px(60);
  min-height: 100svh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  &[data-text-color='dough'] {
    --text-color: var(--dough);
  }

  @include bp(tablet) {
    min-height: 90svh;
  }
}

.inner {
  // overflow: hidden;
  z-index: 3;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: var(--section-max-width);
  margin: 0 auto;
  width: 100%;

  [data-align-items='center'] & {
    align-items: center;
    justify-content: center;
  }

  [data-align-items='left'] & {
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.content {
  width: 100%;
}

.narrowContainer {
  max-width: 1144px;
}

.eyebrow {
  @include font-handle;
  margin-bottom: px(16);

  [data-align-items='center'] & {
    text-align: center;
  }
}

.overlay {
  @include position-100(absolute);
  z-index: 2;
  background-color: $black;
  opacity: var(--overlay-opacity);
}

.mediaContainer {
  @include position-100(absolute);
  z-index: 1;
}

.image,
.video {
  @include position-100(absolute);

  object-fit: cover;
}
