@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.StaggeredImages {
  display: flex;
  flex-direction: column;

  @include bp(tablet, true) {
    gap: px(56);
  }

  &[data-image-count='3'] {
    @include bp(tablet) {
      flex-direction: row;
      @include grid;
    }
  }

  &[data-image-count='2'] {
    @include bp(tablet) {
      flex-direction: row;
      @include flex-center;
      gap: px(136);
    }
  }
}
