@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.HeaderWithCtas {
}

.portableText {
  color: var(--text-color);

  [data-align-items='center'] & {
    text-align: center;
  }
}

.rtaNormalText {
  margin-top: px(10);
  @include font-detail-l-display;
}

.rtaH1Text {
  @include bp(tablet) {
    [data-desktop-title-size='xl'] & {
      @include font-h1;
    }

    [data-desktop-title-size='large'] & {
      @include font-h3;
    }

    [data-desktop-title-size='medium'] & {
      @include font-h4;
    }
  }

  @include bp(tablet, true) {
    [data-mobile-title-size='xl'] & {
      @include font-h1;
    }
    
    [data-mobile-title-size='large'] & {
      @include font-h3;
    }

    [data-mobile-title-size='medium'] & {
      @include font-mobile-media-background-title-small;
    }
  }
}

.rtaAsterisk {
  @include red-asterisk;
  align-items: center;
  display: inline-flex;
  vertical-align: bottom;
  margin-right: px(7);
}

.buttons {
  margin-top: px(48);
  display: flex;
  gap: px(16);
  flex-flow: row wrap;

  @include bp(tablet) {
    margin-top: px(56);
  }

  [data-align-items='center'] & {
    align-items: center;
    justify-content: center;
  }
}

.emailSignupInput {
  z-index: 5;
  margin-top: px(48);
  width: 100%;
  max-width: px(672);

  @include bp(tablet) {
    margin-top: px(64);
  }

  [data-align-items='center'] & {
    margin: px(48) auto 0;

    @include bp(tablet) {
      margin: px(64) auto 0;
    }
  }
}
