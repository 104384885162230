@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.MenuListing {
  padding: 0 var(--page-gutter);
}

.inner {
  max-width: var(--section-max-width);
  margin: 0 auto;
}

.categoryContainer {
  margin-top: calc(var(--header-height) * -1);

  @include bp(laptop) {
    margin-top: 0;
  }

  &:not(:first-child) {
    padding-top: calc(var(--header-height) + var(--content-with-sticky-nav-button-height) + #{px(20)});

    @include bp(laptop) {
      padding-top: calc(var(--header-height) + #{px(20)});
    }
  }

  &:first-child {
    @include bp(laptop, true) {
      padding-top: calc(var(--header-height) + var(--content-with-sticky-nav-button-height) + #{px(20)});
    }
  }
}

.categoryTitle {
  @include font-sh3;
  margin-bottom: px(48);
}

.itemsList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: var(--gutter);
  row-gap: px(36);

  @include bp(mobile) {
    gap: var(--gutter);
    grid-template-columns: repeat(3, 1fr);
  }
}

.bottomNavContent {
  margin-top: px(24);
  border-top: 1px solid rgba(var(--fg-rgb), 0.1);
  padding-top: px(16);
}

.bottomNavContent__allergenText {
  @include font-body-s;
}

.bottomNavContent__button {
  margin-top: px(24);
}
