@import "@/styles/shared";
// stylelint-disable property-no-vendor-prefix

.dateInputReset {
  // Remove the default appearance
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  // Reset to a basic text input style
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 0;
  padding: 8px;
  font-family: inherit;
  font-size: 16px;
  color: rgba(var(--fg-rgb), #{$placeholder-opacity});

  // Remove the calendar icon
  &::-webkit-calendar-picker-indicator {
    display: none;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // For Firefox
  &::-moz-calendar-picker-indicator {
    display: none;
  }

  // Remove clear button in IE
  &::-ms-clear {
    display: none;
  }

  // Style the placeholder text
  &::placeholder {
    color: #999;
  }

  // Style for focus state
  &:focus {
    outline: none;
    border-color: var(--ginger);
  }
}

// Additional styles to handle Safari specifically
@supports (-webkit-touch-callout: none) {
  .dateInputReset {
    // Prevent zoom on focus in iOS
    font-size: 16px;
  }
}
