@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.LocationHeroHours {
  --border-color: rgba(#{$black-rgb}, 0.1);

  margin: px(16) 0;
  padding: px(16) 0;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);

  @include bp(tablet) {
    margin: px(24) 0;
    padding: px(24) 0;
  }
}

.openClosedBlurb {
  margin-bottom: px(16);
}

.dayHoursList__item,
.holidayHoursList__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.holidayHoursList__itemTitle,
.dayHoursList__item__day {
  @include font-detail;
}

.dayHoursList__item__hours,
.holidayHoursList__itemHours {
  @include font-detail;
}

.holidayHoursContainer {
  margin-top: px(24);
}

.titleButton {
  @include reset-button;
  display: flex;
  align-items: center;
  margin-bottom: px(24);
}

.titleButton__text {
  @include font-body-s;
  margin-right: px(5);
}

.titleButton__iconContainer {
  width: px(20);
  transform: translateY(#{px(-2)});

  [data-holidays-opened='true'] & {
    transform: rotate(180deg);
  }
}
