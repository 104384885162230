@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.Footer {
  color: var(--text-color);
  padding: 0 var(--page-gutter);
}

.inner {
  max-width: var(--section-max-width);
  margin: 0 auto;
  width: 100%;
}

.top {
  @include bp(tablet) {
    display: grid;
    grid-template-columns: px(200) 1fr;
    gap: var(--gutter);
  }
}

.leftSide {
  display: none;

  @include bp(tablet) {
    display: block;
  }
}

.rightSide {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  column-gap: var(--gutter);
  row-gap: px(48);
  width: 100%;

  @include bp(tablet) {
    width: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }
}

.column {
  @include bp(tablet) {
    width: px(200);
  }
}

.columnTitle {
  @include font-body-xl-display;
  opacity: 0.6;
  margin-bottom: px(24);
}

.columnList__item {
  &:not(:first-child) {
    margin-top: px(16);
  }
}

.columnList__link {
  @include font-button;
}

.columnList__link__active {
  color: rgba(var(--fg-rgb), 0.5);
}

.bottom {
  margin-top: px(60);
  padding-top: px(30);
  border-top: 1px solid rgba(var(--fg-rgb), 0.2);
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: px(32);

  @include bp(tablet) {
    flex-direction: row;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: px(100);
  }
}

.languageSwitcherContainer {
  display: flex;
  justify-content: flex-start;
}

.socialMediaLinks {
  @include flex-center;
  gap: px(24);
}

.socialMediaLinks__link {
  @include font-body;
}

.legalContainer {
  display: flex;
  justify-content: flex-end;
}

.legalText {
  @include font-body-s;
  color: rgba(var(--fg-rgb), 0.6);
}
