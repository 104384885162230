@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.MenuListingListItem {
  display: flex;
  flex-direction: column;
}

.linkContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.imageContainer {
  aspect-ratio: 329/352;
  position: relative;
  background-color: var(--bg-subtle-color);
}

.image {
  @include position-100(absolute);
  object-fit: cover;
}

.textContent {
  @include flex-center;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  padding: px(16) px(16) 0;
  flex: 1;
  gap: px(35);
  height: 100%;
  transition: background-color $transition-short;

  @include bp(mobile) {
    padding: px(16) px(16) px(60);
  }

  .isHovering & {
    background-color: var(--bg-subtle-color);
  }
}

.title {
  @include font-body-l-display;
}

.description {
  @include font-body-s;
  margin-top: px(8);
  overflow: hidden;
  text-overflow: ellipsis;

  // stylelint-disable-next-line value-no-vendor-prefix
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  display: none;

  @include bp(mobile) {
    // stylelint-disable-next-line value-no-vendor-prefix
    display: -webkit-box;
  }
}

.buttonContainer {
  opacity: 0;
  transition: opacity $transition-short;
  display: none;

  @include bp(mobile) {
    display: block;
  }

  .isHovering & {
    opacity: 1;
  }
}
