@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.HomepageHero {
  position: relative;
  overflow: hidden;
  height: 100svh;
  min-height: px(650);
  padding: var(--section-spacing) 0 0;
  color: var(--text-color);

  @include bp(tablet) {
    height: calc(100svh - #{px(24)});
  }
}

.inner {
  position: relative;
  z-index: 4;
  max-width: var(--section-max-width);
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.dtfLogoContainer {
  @include transform-center;
  position: absolute;
  max-width: px(340);
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 3;

  @include bp(tablet) {
    max-width: px(650);
  }

  @include bp(desktop) {
    max-width: px(800);
  }
}

.dtfLogo {
  width: 100%;
}

.lowerContent {
  @include flex-center;
  flex-direction: column;
  z-index: 2;
  position: relative;
  padding-bottom: px(40);
}

.buttons {
  @include flex-center;
  gap: px(16);
  margin-bottom: px(32);
}

.subtitle {
  @include font-detail-l-display;
}

.scrollIndicator {
  @include reset-button;
  @include flex-center;
  display: none;
  @include box(px(56));
  border-radius: 50%;
  border: 1px solid rgba(var(--fg-rgb), 0.6);
  overflow: hidden;
  position: relative;
  margin: px(40) 0 0;

  @include bp-height(860) {
    display: flex;
  }

  @include bp(tablet, true) {
    display: flex !important;
  }
}

.scrollIndicator__icon {
  width: px(28);
}

.overlay {
  @include position-100(absolute);
  z-index: 2;
  background-color: $black;
  opacity: var(--overlay-opacity);
}

.mediaContainer {
  @include position-100(absolute);
  z-index: 1;
}

.image,
.video {
  @include position-100(absolute);
  object-fit: cover;
}
