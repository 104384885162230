@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.GuestServicesHero {
  padding: 0 var(--page-gutter);
}

.inner {
  @include grid;
  max-width: var(--section-max-width);
  margin: 0 auto;

  @include bp(tablet) {
    align-items: center;
  }
}

.textSide {
  grid-column: 1/-1;
  order: 2;

  @include bp(tablet) {
    order: 1;
    grid-column: 1/7;
  }
}

.title {
  @include font-h3;
  margin-bottom: px(43);
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: px(10);
  flex-flow: row wrap;
}

.phoneNumberEmailContainer {
  margin-top: px(56);
  margin-bottom: px(24);

  @include bp(tablet) {
    margin-top: px(72);
  }
}

.phoneNumber,
.email {
  @include font-body;
}

.imageSide {
  grid-column: 1/-1;
  order: 1;
  margin-bottom: px(23);

  @include bp(tablet) {
    margin-bottom: 0;
    order: 2;
    grid-column: 7/-1;
  }
}

.imageContainer {
  @include box-styling(arch);
}

.image {
  @include position-100(absolute);
  object-fit: cover;
}
