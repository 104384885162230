@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.EcommerceItemList {
  padding: 0 var(--page-gutter);
}

.inner {
  max-width: var(--section-max-width);
  margin: 0 auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat(1 , 1fr);
  column-gap: var(--gutter);
  row-gap: px(100);

  @include bp(mobile) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.item {
  width: 100%;
}
