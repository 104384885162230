@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.TextAndAccordion {
  padding: 0 var(--page-gutter);
}

.inner {
  @include grid;
  max-width: var(--section-max-width);
  margin: 0 auto;
}

.textSide {
  grid-column: 1/-1;
  order: 2;

  @include bp(tablet) {
    order: 1;
    grid-column: 1/6;
  }

  [data-content-type='image'] & {
    @include bp(tablet) {
      grid-column: 1/7;
      padding-right: var(--gutter);
    }
  }
}

.mediaContainer {
  @include box-styling(roundedCorners);
}

.image,
.video {
  @include position-100(absolute);
  object-fit: cover;
}

.title {
  @include font-h5;
  margin-bottom: px(48);
  white-space: pre-line;
}

.description {
  @include font-body;
  white-space: pre-line;

  p {
    margin-bottom: px(15);
  }
}

.desktopButton {
  margin-top: px(48);
  display: none;

  @include bp(tablet) {
    display: block;
  }
}

.accordionSide {
  grid-column: 1/-1;
  order: 2;

  @include bp(tablet) {
    order: 1;
    grid-column: 7/-1;
  }
}

.accordion {
  @include bp(mobile, true) {
    margin-bottom: px(24);
  }
}

.pressReleaseContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: px(36);
}

.pressReleaseItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: px(12);
}

.pressReleaseItem__date {
  @include font-body-s;
}

.pressReleaseItem__title {
  @include font-body;
}
