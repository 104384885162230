@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.RichTextHero {
  padding: 0 var(--page-gutter);
}

.inner {
  max-width: var(--section-max-width);
  margin: 0 auto;
}

.grid {
  @include grid;
}

.gridContent {
  grid-column: 1;

  @include bp(tablet) {
    grid-column: 2/12;
  }
}
