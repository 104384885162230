@import "@/styles/shared";
/* Base container styles */
.HomepageHeroLNY {
  position: relative;
  overflow: hidden;
  height: 100svh;
  min-height: px(650);
  padding: var(--section-spacing) 0 0;
  color: var(--text-color);

  @include bp(tablet) {
    height: calc(100svh - #{px(24)});
  }
}

/* Inner content container */
.inner {
  position: relative;
  z-index: 4;
  max-width: var(--section-max-width);
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: px(56);
  visibility: hidden; // Initial state for GSAP

  @include bp(laptop, tue) {
    padding: 0 px(24);
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: px(24);
}

.title {
  @include font-h1;
  margin-left: px(-12);

  @include bp(xsmobile, true) {
    font-size: 4.2rem;
  }
}

/* Lower content area */
.ctas {
  @include flex-center;
  flex-direction: column;
  z-index: 2;
  position: relative;
  padding-bottom: px(40);
}

/* Button container */
.buttons {
  @include flex-center;
  gap: px(16);
  margin-bottom: px(32);

  @include bp(laptop, tue) {
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
}

/* Typography */
.subtitle {
  @include font-detail-l-display;
  display: flex;
  align-items: center;
  gap: px(8);
}

.italic {
  font-style: italic;
}

/* Background overlay */
.overlay {
  @include position-100(absolute);
  z-index: 2;
  background-color: $black;
  visibility: hidden; // Initial state for GSAP
}

/* Media container */
.mediaContainer {
  @include position-100(absolute);
  z-index: 1;
}

/* Media elements */
.image,
.video {
  @include position-100(absolute);
  object-fit: cover;
}

.imageContainer {
  position: relative;
  width: 159px;
  height: 62px;
}