@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.ValuesList {
  padding: 0 var(--page-gutter);
}

.inner {
  max-width: var(--section-max-width);
  margin: 0 auto;
}

.list {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: var(--gutter);
  row-gap: px(56);

  @include bp(mobile) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include bp(tablet) {
    row-gap: px(96);
    grid-template-columns: repeat(3, 1fr);
  }
}

.itemContainer {
  display: flex;
  flex-direction: column;

  &:nth-child(3n + 1) .mediaContainerInner,
  &:nth-child(3n) .mediaContainerInner {
    background-color: var(--soft-ginger);
  }

  &:nth-child(3n + 2) .mediaContainerInner {
    background-color: rgba(var(--flour-rgb), 0.5);
  }
}

.mediaContainerInner {
  position: relative;
  overflow: hidden;

  .arch & {
    @include box-styling(arch);
    aspect-ratio: 1/1;
  }

  .roundedCorners & {
    @include box-styling(roundedCorners);
  }
}

.image {
  @include position-100(absolute);
  object-fit: cover;
}

.title {
  @include font-sh3;
  margin-top: px(11);

  @include bp(tablet) {
    margin-top: px(32);
  }
}

.descriptionText {
  @include font-body-l;
  margin-top: px(12);

  @include bp(tablet) {
    margin-top: px(24);
  }
}
