@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.GiftCardGrid {
  padding: 0 var(--page-gutter);

  --stagger-y: #{px(120)};
  --offset: calc(#{px(130)} + var(--stagger-y));

  &[data-is-even='true'] {
    padding: 0 var(--page-gutter) var(--offset);
  }

  &.inView {
    --offset: #{px(130)};
  }
}

.inner {
  @include grid;
  max-width: var(--section-max-width);
  margin: 0 auto;
}

.grid {
  grid-column: 1/-1;
  width: 100%;
  gap: calc(var(--gutter) * 2);
  display: flex;
  flex-direction: column;

  @include bp(tablet) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column: 2/12;
  }
}

.itemContainer {
  aspect-ratio: 692 / 435;
  position: relative;
  width: 70%;
  transition: transform 0.85s;
  transition-delay: var(--stagger-delay);

  @include bp(tablet) {
    width: 100%;
    transform: translateY(var(--stagger-y));

    .inView & {
      transform: none;
    }
  }

  &:nth-child(even) {
    align-self: flex-end;

    @include bp(tablet) {
      align-self: auto;
      transform: translateY(var(--offset));
    }
  }
}

.image {
  @include position-100(absolute);
  object-fit: contain;
}
