@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.LocationsList {
  padding: 0 var(--page-gutter);
}

.inner {
  max-width: var(--section-max-width);
  margin: 0 auto;

  @include bp(mobile, true) {
    min-height: 100svh;
  }
}

.header {
  @include grid;
  position: relative;

  @include bp(tablet) {
    padding-bottom: var(--section-spacing);
  }
}

.headerContent {
  position: relative;
  z-index: 2;
  grid-column: 1/-1;

  @include bp(tablet) {
    grid-column: 2/11;
  }
}

.imageContainer {
  position: absolute;
  grid-column: 2/12;
  padding-top: 0;
  display: none;
  margin-top: px(-100);

  @include bp(laptop) {
    display: block;
  }
}

.image {
  width: 100%;
  height: auto;
}

.listItem {
  margin-top: calc(var(--header-height) * -1);

  @include bp(laptop) {
    margin-top: 0;
  }

  &:not(:first-child) {
    padding-top: calc(var(--header-height) + var(--content-with-sticky-nav-button-height) + #{px(20)});

    @include bp(laptop) {
      padding-top: calc(var(--header-height) + #{px(20)});
    }
  }

  &:first-child {
    @include bp(laptop, true) {
      padding-top: calc(var(--header-height) + var(--content-with-sticky-nav-button-height) + #{px(20)});
    }
  }
}

.listItem__title {
  @include font-sh3;
  margin-bottom: px(40);

  @include bp(tablet) {
    margin-bottom: px(32);
  }
}

.locationsList__item {
  --border-color: rgba(#{$black-rgb}, 0.1);

  border-top: 1px solid var(--border-color);

  &:last-child {
    border-bottom: 1px solid var(--border-color);
  }
}

.contentWithStickyNav {
  padding-top: px(80);
}
