@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.MerchandiseListingItem {
  @include reset-button;
  display: block;
  width: 100%;
}

.imageContainer {
  aspect-ratio: 1/1;
  position: relative;
  display: block;
}

.image {
  @include position-100(absolute);
  object-fit: cover;
}

.title {
  @include font-body-xl-display;
  margin-top: px(24);
  margin-bottom: px(8);
  display: block;
}

.price {
  @include font-body-xl-display;
  margin-bottom: px(24);
  margin-top: px(8);
  display: block;
}

.cta {
  margin-bottom: px(72);

  @include bp(tablet) {
    margin-bottom: px(86);
  }
}
