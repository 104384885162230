@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.FooterLanguageSwitcher {
}

.link {
  @include font-sh5;
  padding: px(2) px(15);
  border-radius: px(100);
  border: 1px solid transparent;
  color: var(--text-color);

  &.isActive {
    border-color: rgba(var(--fg-rgb), 0.4);
  }
}
