@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.SectionContainer {
  margin: var(--section-spacing) 0;
  min-height: px(50); // hack for fixing page-to-page issues with sections indicating they're in view
  color: var(--text-color);

  &:first-child {
    margin-top: 0;
    padding-top: calc(var(--section-spacing) + var(--stickybanner-height)) !important;

    @include bp(tablet) {
      padding-top: calc(var(--section-spacing) + var(--stickybanner-height-xsmobile)) !important;
    }
  }
}

@each $colour in $background-colours {
  .SectionContainer[data-section-background='#{$colour}'] {
    background-color: var(--#{$colour});

    @if $colour == black or $colour == burgundy {
      @include dark-theme;
    }

    &:not([data-section-background-previous='#{$colour}']) {
      padding-top: var(--section-spacing);
      margin-top: 0;
    }

    &:not([data-section-background-next='#{$colour}']) {
      padding-bottom: var(--section-spacing);
      margin-bottom: 0;
    }

    &[data-section-background-next='#{$colour}'] {
      margin-bottom: 0;
    }

    &+[data-section-background='#{$colour}'] {
      margin-top: 0;
      padding-top: var(--section-spacing) !important;
    }
  }
}

// Anti-Pattern OVERRIDES

// The extra selectors are purely for overriding
section.SectionContainer[data-component='mediaBackgroundAndTitle'][data-section-background],
section.SectionContainer[data-component='homepageHero'][data-section-background],
section.SectionContainer[data-component='homepageHeroLNY'][data-section-background] {
  margin-top: 0;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.SectionContainer[data-section-next-section-type='mediaBackgroundAndTitle'] {
  padding-bottom: var(--section-spacing);
  margin-bottom: 0;
}

.SectionContainer[data-component='footer'] {
  padding-top: var(--section-spacing) !important;
  padding-bottom: px(55) !important;

  &[data-section-background-previous='black'] {
    margin-top: 0;
  }

  &:has([data-extra-padding='true']) {
    padding-bottom: var(--section-spacing) !important;
  }
}