@import "@/styles/shared";
.RichTextContent {
  position: relative;
  padding: 0 var(--page-gutter);
}

.inner {
  @include grid;
  max-width: var(--section-max-width);
  margin: 0 auto;
}

.richTextContentContainer {
  grid-column: 1/-1;

  @include bp(laptop) {
    grid-column: 4/-1;
  }
}

.contentText {
  @include font-body;
  @include rich-text-p-margin;
}

.contentList,
.contentOrderedList {
  padding-left: 1em;
  @include rich-text-p-margin;
}

.contentListElement {
  list-style: disc;
}

.contentH1 {
  @include font-sh1;
  @include rich-text-p-margin;
}

.contentH2 {
  @include font-sh2;
  @include rich-text-p-margin;
}

.contentH3 {
  @include font-sh3;
  @include rich-text-p-margin;
  font-size: px(23) !important;
}

.richTextLink {
  text-decoration: underline;
}
