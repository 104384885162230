@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.EcommerceItemCard {
  height: 100%;
}

.linkContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.imageContainer {
  @include box-styling(square);
  width: 100%;
}

.image {
  @include position-100(absolute);
  object-fit: cover;
}

.title {
  @include font-body-xl-display;
  margin-top: px(24);
}

.price {
  @include font-body-xl-display;
  margin-top: px(8);
  margin-bottom: px(24);
}

.buttonContainer {
  width: max-content;
  margin-top: auto;
}
