@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.LocationHeroImageCarousel {
}

.carouselContainerMaster {
  @include box-styling(arch);
}

.carouselContainer {
  position: relative;
  height: 100%;
}

.carouselContainerInner {
  position: relative;
  height: 100%;
}

.imageContainer {
  position: relative;
  height: 100%;
}

.image {
  @include position-100(absolute);
  object-fit: cover;
}

.pagination {
  @include flex-center;
  margin-top: px(16);
}

.paginationButton {
  @include reset-button;
  padding: px(12) px(3);
}

.paginationButtonInner {
  width: px(24);
  height: px(2.5);
  background-color: rgba(var(--fg-rgb), 0.1);
  transition: background-color $transition-short;
  display: block;

  .isActive & {
    background-color: var(--red);
  }
}
