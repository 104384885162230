@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.FourOhFour {
  padding: 0 var(--page-gutter);
}

.inner {
  @include grid;
  max-width: var(--section-max-width);
  margin: 0 auto;
  grid-gap: px(32);
}

.textContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: px(24);
  grid-column: 1 / -1;
}

.title {
  @include font-h3;
}

.description {
  @include font-body-xl;
}

.mediaContent {
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.imageContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 1/1;
  max-width: px(400);
}

.image,
.video {
  @include position-100(absolute);
  object-fit: contain;
}
