@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.Timeline {
  padding: 0 var(--page-gutter);
  position: relative;
  overflow: hidden;
}

.inner {
  max-width: var(--section-max-width);
  margin: 0 auto;
}

.carouselContainer {
  position: relative;
  z-index: 2;
}

.listWrapper__item {
  width: 100%;

  @include bp(laptop) {
    height: auto;
    display: flex;
    align-items: center;
    width: px(1000);

    &.noImage {
      width: px(700);
    }
  }
}

.header {
  display: flex;
  flex-direction: column;
  margin-bottom: px(32);
  position: relative;
  z-index: 5;

  @include bp(tablet) {
    margin-bottom: px(70);
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
}

.title {
  @include font-h5;
  margin-bottom: px(16);

  @include bp(tablet) {
    margin-bottom: 0;
  }
}

.dropdownContainer {
  position: relative;
  width: max-content;
}

.customDropdown {
  width: 100%;
}

.progressBarContainer {
  width: 100%;
  height: 2px;
  position: relative;
  background-color: var(--flour);
  margin-top: px(40);
}

.progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: width $transition-short;
  background-color: var(--text-color);
}
