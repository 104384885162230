@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.EmailSignup {
  position: relative;
  padding: 0 var(--page-gutter);
  color: var(--text-color);
}

.inner {
  max-width: var(--section-max-width);
  margin: 0 auto;
}

.header {
  margin-bottom: px(32);

  @include bp(tablet) {
    margin-bottom: px(56);
  }

  h1 {
    @include font-h3;
  }
}

.smallHeader {
  border-top: 1px solid rgba(var(--fg-rgb), 0.1);
  color: var(--text-color);
  text-transform: uppercase;
  margin-bottom: px(32);
  padding-top: px(16);

  @include bp(tablet) {
    margin-bottom: px(56);
  }
}
