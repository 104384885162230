@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.PressHighlightListing {
  padding: 0 var(--page-gutter);
}

.inner {
  max-width: var(--section-max-width);
  margin: 0 auto;

  --row-gap: #{px(56)};

  @include bp(tablet) {
    --row-gap: #{px(72)};
  }
}

.list {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: var(--gutter);
  row-gap: var(--row-gap);

  @include bp(mobile) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include bp(tablet) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.buttonContainer {
  margin-top: var(--row-gap);
  @include flex-center;
}
