@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.ThreeUpMenuItemCard {
  &.isStaggeredStyle:nth-child(2) {
    @include bp(tablet) {
      margin-top: px(208);
    }
  }

  &.isStaggeredStyle:nth-child(3) {
    @include bp(tablet) {
      margin-top: px(104);
    }
  }
}

.linkContainer {
  display: block;

  @include hover {
    .image {
      transform: scale(1.06);
    }
  }
}

.imageContainer {
  width: 100%;
  position: relative;
  aspect-ratio: 1/ 1;
  margin-bottom: px(21);
  overflow: hidden;

  .ThreeUpMenuItemCard:nth-child(1).isStaggeredStyle & {
    @include box-styling(roundedCorners);
  }

  .ThreeUpMenuItemCard:nth-child(2).isStaggeredStyle & {
    @include box-styling(arch);
  }

  .ThreeUpMenuItemCard:nth-child(3).isStaggeredStyle & {
    @include box-styling(roundedCorners);
  }
}

.image {
  @include position-100(absolute);
  object-fit: cover;
  transition: transform 0.8s;
}

.title {
  @include font-body-xl-display;

  .isStaggeredStyle & {
    text-align: center;
  }
}
