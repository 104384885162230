@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.RestaurantOpenClosedBlurb {
  display: flex;
  align-items: center;
  gap: px(6);
}

.dot {
  min-height: px(8);
  min-width: px(8);
  border-radius: 50%;
  display: block;

  [data-is-open='false'] & {
    background-color: var(--red);
  }

  [data-is-open='true'] & {
    background-color: var(--jade-green);
  }

  [data-is-coming-soon='true'] & {
    background-color: rgba(var(--fg-rgb), 0.5);
  }
}

.text {
  @include font-body-s;
}
