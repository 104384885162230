@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.MerchandiseListing {
  @include dark-theme;
  position: relative;
  color: var(--text-color);
  padding: 0 var(--page-gutter);
  min-height: 100svh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  &[data-text-color='dough'] {
    --text-color: var(--dough);
  }

  @include bp(tablet) {
    min-height: 90svh;
  }
}

.header {
  margin-bottom: px(30);

  @include bp(mobile) {
    margin-bottom: px(30);
    display: grid;
    grid-template-columns: 1fr #{px(200)};
    align-items: center;
  }
}

.inner {
  // overflow: hidden;
  z-index: 3;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: var(--section-max-width);
  margin: 0 auto;
  width: 100%;

  [data-align-items='center'] & {
    align-items: center;
    justify-content: center;
  }

  [data-align-items='left'] & {
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.content {
  width: 100%;
}

.subHeader {
  margin-bottom: px(24);

  @include bp(mobile) {
    margin-bottom: px(88);
    display: grid;
    grid-template-columns: 1fr #{px(200)};
    align-items: center;
  }
}

.title {
  @include font-h5;
  white-space: pre-line;
}

.subtitle {
  @include font-body;
  white-space: pre-line;
}

.mediaContainer {
  @include position-100(absolute);
  z-index: 1;
}

.image {
  @include position-100(absolute);

  object-fit: cover;
}

.merchandiseContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, 2fr);
  gap: var(--gutter);
  margin-top: var(--section-spacing);
}

@media (min-width: 768px) {
  .merchandiseContainer {
    gap: var(--section-spacing);
  }
}

.items {
  position: relative;

  .ThreeUp[data-list-style='horizontalScroll'] & {
    @include hide-scrollbar;
    width: calc(100% + calc(var(--page-gutter) * 2));
    margin-left: calc(var(--page-gutter) * -1);
    overflow: auto;

    @include bp(tablet) {
      margin-left: 0;
      width: 100%;
    }
  }

  @include bp(tablet) {
    width: 100%;
    overflow: hidden;
    margin-left: 0;
    margin-top: px(88);
  }
}

.itemsInner {
  .MerchandiseListingItem[data-list-style='horizontalScroll'] & {
    width: calc(93vw * var(--items-count));
    padding: 0 var(--page-gutter);
    gap: var(--gutter);
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @include bp(tablet) {
      width: 100%;
      padding: 0;
    }
  }

  .MerchandiseListingItem {
    display: flex;
    flex-direction: column;
    gap: px(52);

    @include bp(tablet) {
      display: grid;
      flex-direction: row;
      gap: var(--gutter);
    }
  }

  @include bp(tablet) {
    gap: var(--gutter);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

.item {
  width: 100%;

  .MerchandiseListingItem & {
    width: 66vw;

    &:nth-child(even) {
      align-self: flex-end;
    }

    @include bp(tablet) {
      width: 100%;
    }
  }
}
