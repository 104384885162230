@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.Breadcrumbs {
  display: flex;
  align-items: center;
  gap: px(8);
}

.link {
  @include font-button;

  &:not(:first-child) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    max-width: 100%;
  }
}

.icon {
  min-width: px(16);
  max-width: px(16);
}
