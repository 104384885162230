@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.MerchandiseShowcase {
  padding: 0 var(--page-gutter);
  overflow: hidden;
}

.inner {
  max-width: var(--section-max-width);
  margin: 0 auto;
}

.header {
  margin-bottom: px(72);

  @include bp(mobile) {
    margin-bottom: px(88);
    display: grid;
    grid-template-columns: 1fr #{px(200)};
    align-items: center;
  }
}

.paginationContainer {
  display: none;

  @include bp(mobile) {
    @include flex-center;
    justify-content: flex-end;
    gap: px(12);
  }
}

.button {
  @include reset-button;
  @include box(px(56));
  @include flex-center;
  border: 1px solid rgba(var(--fg-rgb), 0.4);
  border-radius: 50%;
  transition: opacity #{$transition-short};
  opacity: 0.5;
  pointer-events: none;
}

.prev {
  .MerchandiseShowcase[data-allowed-prev='true'] & {
    pointer-events: all;
    opacity: 1;
  }
}

.next {
  .MerchandiseShowcase[data-allowed-next='true'] & {
    pointer-events: all;
    opacity: 1;
  }
}

.icon {
  width: px(24);

  .prev & {
    transform: rotate(180deg);
  }
}

.title {
  @include font-h5;
  white-space: pre-line;
}
