@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.ThreeUp {
  padding: 0 var(--page-gutter);
  color: var(--text-color);
}

.inner {
  max-width: var(--section-max-width);
  margin: 0 auto;
}

.titleCtaContainer {
  @include bp(tablet) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.title {
  @include font-h5;
  margin-bottom: px(32);

  @include bp(tablet) {
    margin-bottom: 0;
  }
}

.cta {
  margin-bottom: px(72);

  @include bp(tablet) {
    margin-bottom: 0;
  }
}

.items {
  position: relative;

  .ThreeUp[data-list-style='horizontalScroll'] & {
    @include hide-scrollbar;
    width: calc(100% + calc(var(--page-gutter) * 2));
    margin-left: calc(var(--page-gutter) * -1);
    overflow: auto;

    @include bp(tablet) {
      margin-left: 0;
      width: 100%;
    }
  }

  @include bp(tablet) {
    width: 100%;
    overflow: hidden;
    margin-left: 0;
    margin-top: px(88);
  }
}

.itemsInner {
  .ThreeUp[data-list-style='horizontalScroll'] & {
    width: calc(93vw * var(--items-count));
    padding: 0 var(--page-gutter);
    gap: var(--gutter);
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @include bp(tablet) {
      width: 100%;
      padding: 0;
    }
  }

  .ThreeUp[data-list-style='staggered'] &,
  .ThreeUp[data-list-style='listed'] & {
    display: flex;
    flex-direction: column;
    gap: px(52);

    @include bp(tablet) {
      display: grid;
      flex-direction: row;
      gap: var(--gutter);
    }
  }

  @include bp(tablet) {
    gap: var(--gutter);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

.item {
  width: 100%;

  .ThreeUp[data-list-style='staggered'] & {
    width: 66vw;

    &:nth-child(even) {
      align-self: flex-end;
    }

    @include bp(tablet) {
      width: 100%;
    }
  }
}
