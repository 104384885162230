@import "@/styles/shared";
.ContactFormField {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
}

.formInputText {
  @include font-body;
  background: transparent;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid rgba(var(--fg-rgb), 0.1);
  padding: px(10) 0;
  color: rgba(var(--fg-rgb), #{$placeholder-opacity});
}

.formInputTextError {
  border-bottom: 1px solid var(--red);
}

.textArea {
  height: px(200);
}
