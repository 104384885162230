@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.StaggeredImages {
  padding: 0 var(--page-gutter);
}

.inner {
  max-width: var(--section-max-width);
  margin: 0 auto;
}
