$font-multiplier: 0.75;
$font-h1-desktop: px(143);
$font-h2-desktop: px(119);
$font-h3-desktop: px(99);
$font-h4-desktop: px(83);
$font-h5-desktop: px(69);
$font-sh1-desktop: px(57);
$font-sh2-desktop: px(48);
$font-sh3-desktop: px(40);
$font-sh4-desktop: px(33);
$font-sh5-desktop: px(28);
$font-body-xl-desktop: px(23);
$font-body-xl-display-desktop: px(23);
$font-body-l-desktop: px(19);
$font-body-l-display-desktop: px(19);
$font-body-desktop: px(16);
$font-detail-l-display-desktop: px(16);
$font-detail-desktop: px(16);
$font-handle-desktop: px(16);

@mixin font-h1 {
  font-family: $display-fonts;
  font-weight: 400;
  line-height: 110%;
  font-size: px(48);

  @include bp(tablet) {
    font-size: $font-h1-desktop * $font-multiplier;
  }

  @include bp(laptop) {
    font-size: $font-h1-desktop;
  }
}

@mixin font-h2 {
  font-family: $display-fonts;
  font-weight: 400;
  line-height: 110%;
  font-size: px(48);

  @include bp(tablet) {
    font-size: $font-h2-desktop * $font-multiplier;
  }

  @include bp(laptop) {
    font-size: $font-h2-desktop;
  }
}

@mixin font-h3 {
  font-family: $display-fonts;
  font-weight: 400;
  line-height: 110%;
  font-size: px(48);

  @include bp(tablet) {
    font-size: $font-h3-desktop * $font-multiplier;
  }

  @include bp(laptop) {
    font-size: $font-h3-desktop;
  }
}

@mixin font-h4 {
  font-family: $display-fonts;
  font-weight: 400;
  line-height: 110%;
  font-size: px(48);

  @include bp(tablet) {
    font-size: $font-h4-desktop * $font-multiplier;
  }

  @include bp(laptop) {
    font-size: $font-h4-desktop;
  }
}

@mixin font-h5 {
  font-family: $display-fonts;
  font-weight: 400;
  line-height: 110%;
  font-size: px(48);

  @include bp(tablet) {
    font-size: $font-h5-desktop * $font-multiplier;
  }

  @include bp(laptop) {
    font-size: $font-h5-desktop;
  }
}

@mixin font-sh1 {
  font-family: $display-fonts;
  font-weight: 400;
  line-height: 110%;
  font-size: px(48);

  @include bp(tablet) {
    font-size: $font-sh1-desktop * $font-multiplier;
  }

  @include bp(laptop) {
    font-size: $font-sh1-desktop;
  }
}

@mixin font-sh2 {
  font-family: $display-fonts;
  font-weight: 400;
  line-height: 110%;
  font-size: px(33);

  @include bp(tablet) {
    font-size: $font-sh2-desktop * $font-multiplier;
  }

  @include bp(laptop) {
    font-size: $font-sh2-desktop;
  }
}

@mixin font-sh3 {
  font-family: $display-fonts;
  font-weight: 400;
  line-height: 110%;
  font-size: px(23);

  @include bp(tablet) {
    font-size: $font-sh3-desktop * $font-multiplier;
  }

  @include bp(laptop) {
    font-size: $font-sh3-desktop;
  }
}

@mixin font-sh4 {
  font-family: $display-fonts;
  font-weight: 400;
  line-height: 110%;
  font-size: px(28);

  @include bp(tablet) {
    font-size: $font-sh4-desktop * $font-multiplier;
  }

  @include bp(laptop) {
    font-size: $font-sh4-desktop;
  }
}

@mixin font-sh5 {
  font-family: $display-fonts;
  font-weight: 400;
  line-height: 110%;
  font-size: px(23);

  @include bp(tablet) {
    font-size: $font-sh5-desktop * $font-multiplier;
  }

  @include bp(laptop) {
    font-size: $font-sh5-desktop;
  }
}

@mixin font-body-xl {
  font-family: $body-fonts;
  font-weight: 400;
  line-height: 120%;
  font-size: px(16);

  @include bp(tablet) {
    font-size: $font-body-xl-desktop;
  }
}

@mixin font-body-xl-display {
  font-family: $display-fonts;
  font-weight: 400;
  line-height: 120%;
  font-size: px(19);

  @include bp(tablet) {
    font-size: $font-body-xl-display-desktop;
  }
}

@mixin font-body-xl-display-uppercase {
  font-family: $display-fonts;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
  font-size: px(23);
}

@mixin font-body-l {
  font-family: $body-fonts;
  font-weight: 400;
  line-height: 130%;
  font-size: px(16);

  @include bp(tablet) {
    font-size: $font-body-l-desktop;
  }
}

@mixin font-body-l-display {
  font-family: $display-fonts;
  font-weight: 400;
  line-height: 130%;
  font-size: px(16);

  @include bp(tablet) {
    font-size: $font-body-l-display-desktop;
  }
}

@mixin font-body-display {
  font-family: $display-fonts;
  font-weight: 400;
  line-height: 130%;
  font-size: px(16);
}

@mixin font-body {
  font-family: $body-fonts;
  font-weight: 400;
  line-height: 160%;
  font-size: px(13);

  @include bp(tablet) {
    font-size: $font-body-desktop;
  }
}

@mixin font-body-s {
  font-family: $body-fonts;
  font-weight: 400;
  line-height: 160%;
  font-size: px(13);
}

@mixin font-detail-l-display {
  font-family: $display-fonts;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-size: px(13);

  @include bp(tablet) {
    font-size: $font-detail-l-display-desktop;
  }
}

@mixin font-detail {
  font-family: $body-fonts;
  font-weight: 400;
  line-height: 160%;
  text-transform: uppercase;
  font-size: px(13);

  @include bp(tablet) {
    font-size: $font-detail-desktop;
  }
}

@mixin font-detail-s {
  font-family: $body-fonts;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
  font-size: px(13);
}

@mixin font-handle {
  font-family: $display-fonts;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-size: px(13);

  @include bp(tablet) {
    font-size: $font-handle-desktop;
  }
}

@mixin font-button {
  font-family: $body-fonts;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-size: px(13);
}

/* ====================================
ANTI
PATTERN
TYPES
==================================== */

@mixin font-timeline-title {
  font-family: $display-fonts;
  font-weight: 400;
  font-size: px(32);
  line-height: 110%;

  @include bp(tablet) {
    font-size: px(48);
  }
}

@mixin font-mobile-media-background-title-small {
  font-family: $display-fonts;
  font-weight: 400;
  font-size: px(40);
  line-height: 110%;
}
