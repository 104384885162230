@import "@/styles/shared";
.InputText {
  border: px(1) solid var(--black);
  padding: px(8) px(12);
  border-radius: px(4);
  transition: border-color $transition-short;
  @include font-body;

  &::placeholder {
    color: rgba(var(--fg-rgb), #{$placeholder-opacity});
  }

  &:focus-visible,
  &:focus {
    outline: none;
  }
}

.inputTextColor {
  color: var(--text-color) !important;
}

.focused {
  border-color: var(--ginger);
}
