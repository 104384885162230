@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.ThreeUpLocationCard {
}

.imageContainer {
  @include flex-center;
  width: 100%;
  position: relative;
  aspect-ratio: 1/1;
  margin-bottom: px(16);
  background-color: var(--soft-ginger);
  padding: var(--gutter);
  display: none;

  @include bp(mobile) {
    display: flex;
  }
}

.imageBox {
  position: relative;
  width: 100%;
  height: 80%;

  .tall & {
    max-height: px(200);
  }

  .orientationSquare & {
    max-width: px(150);
    max-height: px(150);
  }

  .shortRect & {
    max-width: px(140);
    max-height: px(140);
  }

  .orientationLong & {
    max-width: px(200);
  }

  .orientationXLong & {
    max-width: px(320);
  }
}

.image {
  @include position-100(absolute);
  object-fit: contain;
}

.date {
  @include font-detail-s;
  margin-bottom: px(24);
}

.title {
  @include font-body-xl-display;
}

.mobileButton {
  margin-top: px(24);
  display: inline-flex;

  @include bp(mobile) {
    display: none;
  }
}

.source {
  display: none;

  @include bp(mobile, true) {
    display: inline-block;
  }
}
