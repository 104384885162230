@import "@/styles/shared";
.ContactForm {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  padding-bottom: px(40); // Note: Adding padding to be sure there's space for error or success messages
}

.formFields {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.formInputText {
  @include font-body;
  display: flex;
  flex: 1;
  background: transparent;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid rgba(var(--fg-rgb), 0.1);
  padding: px(10) 0;
  color: rgba(var(--fg-rgb), #{$placeholder-opacity});
}

.textArea {
  height: px(200);
}

.fieldsContainer {
  @include bp(tablet) {
    margin-bottom: px(40);
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: px(20);
    flex: 1;
  }
}

.fieldWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
}

.field {
  margin-bottom: px(24);

  @include bp(tablet) {
    margin-bottom: 0;
  }
}

.submitContainer {
  justify-content: center;
  align-items: flex-start;
}
