@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.LocationsListItem {
  display: flex;
  flex-direction: column;
  padding: px(40) 0;
  min-height: px(195);
  position: relative;

  @include bp(tablet) {
    padding: 0;
    display: grid;
    grid-template-columns: 1fr #{px(160)} #{px(320)};
    flex-direction: row;
    align-items: center;
    gap: px(20);
  }
}

.blurbColumn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.textSide {
  flex: 1;

  @include hover {
    .hoverRightIcon {
      transform: translate(3px, 2px);
      opacity: 1;
    }

    .areaTitleContainerDesktop,
    .phoneNumberAddressContainer {
      opacity: 0.5;
    }
  }

  @include bp(tablet) {
    padding: px(40) 0;
  }
}

.title {
  @include font-sh5;

  @include bp(tablet) {
    display: flex;
  }
}

.hoverRightIcon {
  opacity: 0;
  width: px(24);
  color: var(--red);
  display: none;
  transform: translateY(2px);
  pointer-events: none;
  transition: transform $transition-short, opacity $transition-short;

  @include bp(tablet) {
    display: inline-block;
  }
}

.areaTitleContainerMobile {
  display: inline;

  @include bp(tablet) {
    display: none;
  }
}

.areaTitleContainerDesktop {
  @include font-body-l-display;
  display: none;
  transition: opacity $transition-short;

  @include bp(tablet) {
    display: block;
  }
}

.areaTitle__at {
  font-style: italic;
  text-transform: lowercase;
}

.phoneNumber,
.address {
  @include font-body-s;
}

.phoneNumberAddressContainer {
  margin-top: px(20);
  transition: opacity $transition-short;
}

.ctaSide {
  position: relative;
  z-index: 2;

  @include bp(tablet) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.openCloseBlurbContainerMobile {
  display: block;
  margin-top: px(8);

  @include bp(tablet) {
    display: none;
  }
}

.openCloseBlurbContainerDesktop {
  display: none;

  @include bp(tablet) {
    display: block;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: px(8);
  margin-top: px(16);

  @include bp(tablet) {
    margin-top: 0;
    @include flex-center;
  }
}

.privateDiningOnlyButtons {
  flex-direction: row;
}

.topButtons, .bottomButtons {
  justify-content: center;
  display: flex;
  gap: px(8);
  width: 100%;
}

.bottomButtons {
  div {
    a {
      width: 100%;
    }
  }
}

.joinWaitlistButton {
  width: 100%;

  button {
    width: 100%;
  }
}

.disabledOrderButtonPlacement {
  opacity: 0;
  pointer-events: none;

  @include bp(tablet, true) {
    display: none;
  }
}

.comingSoonButton {
  margin-top: px(16);

  @include bp(tablet) {
    margin-top: 0;
  }

  [lang='en'] & {
    @include bp(tablet) {
      width: px(298);
    }
  }
}
