@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.Reviews {
  padding: 0 var(--page-gutter);
}

.inner {
  @include grid;
  max-width: var(--section-max-width);
  margin: 0 auto;
}

.textSide {
  grid-column: 1/-1;
  display: flex;
  flex-direction: column;
  order: 2;

  @include bp(tablet) {
    order: 1;
    padding-right: var(--gutter);
    grid-column: 1/7;
  }
}

.title {
  @include font-h5;
  margin-bottom: px(40);
  margin-top: px(30);

  @include bp(tablet) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.reviewContainer {
  margin: auto 0;
}

.quote {
  @include font-sh2;
  margin-bottom: px(30);
}

.authorDesignationContainer {
  @include font-detail-s;
  font-style: normal;
}

.imageSide {
  grid-column: 1/-1;
  order: 1;

  @include bp(tablet) {
    order: 2;
    padding-left: var(--gutter);
    grid-column: 7/-1;
  }
}

.imageContainer {
  @include box-styling(square);
}

.image {
  @include position-100(absolute);
  object-fit: cover;
}
