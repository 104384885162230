@import "@/styles/shared";
/* stylelint-disable property-no-vendor-prefix */
.Select {
  position: relative;
  display: flex;
  align-items: stretch;
}

.selectElement {
  border: px(1) solid rgba(var(--fg-rgb), 1);
  color: rgba(var(--fg-rgb), #{$placeholder-opacity});
  transition: border-color $transition-short;
  @include font-body;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 1.5em;
  display: flex;
  flex: 1;

  &.hasValue {
    color: var(--text-color);
  }

  &:focus-visible,
  &:focus {
    outline: none;
  }
}

.selectTextColor {
  color: var(--text-color) !important;
}

.focused {
  border-color: var(--azurite);
}

.caretIcon {
  width: px(20);
  position: absolute;
  right: px(10);
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
