@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.TimelineItem {
  @include reset-button;
  display: block;
  width: 100%;

  &:not(.hasImage) {
    @include bp(tablet) {
      height: 100%;
      display: flex;
    }
  }
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @include bp(mobile) {
    display: grid;
    grid-template-columns: 0.4fr 0.6fr;
    gap: px(52);
    align-items: flex-start;
  }

  .TimelineItem:not(.hasImage) & {
    @include bp(mobile) {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
}

.imageSide {
  width: 100%;
  margin-bottom: px(31);
  display: block;

  @include bp(mobile) {
    margin-bottom: 0;
  }
}

.imageContainer {
  width: 100%;
  display: block;

  [data-image-style='roundedCorners'] & {
    @include box-styling(roundedCorners);
  }

  [data-image-style='arch'] & {
    @include box-styling(arch);
  }

  [data-image-style='square'] & {
    @include box-styling(square);
  }
}

.image {
  @include position-100(absolute);
  object-fit: cover;
}

.asterisk {
  @include red-asterisk;
  margin-right: px(5);
}

.eyebrow {
  display: flex;
  align-items: center;
  @include font-detail-s;
  margin-bottom: px(24);
}

.title {
  @include font-timeline-title;
}

.rta {
  display: block;
  @include font-body-l;

  @include bp(mobile, true) {
    margin-top: px(32);
  }

  @include bp(mobile) {
    margin-top: px(40);
  }

  .TimelineItem:not(.hasImage) & {
    @include bp(mobile) {
      margin-top: px(24);
      max-width: px(600);
    }
  }

  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
}

.p {
  margin: px(24) 0;
}

.li {
  list-style: disc;
}

.ul {
  margin: px(24) 0;
  padding-left: 1em;
}
