@import "@/styles/shared";
.ContactFormResults {
  padding: px(12) px(0);
}

.ContactFormResultsError {
  color: var(--error-message-text-color);
}

.ContactFormResultsSuccess {
  color: var(--success-message-text-color);
}
