@import "@/styles/shared";
.Textarea {
  border: px(1) solid rgba(var(--fg-rgb), 1);
  color: var(--text-color);
  transition: border-color $transition-short;
  @include font-body;

  &:focus-visible,
  &:focus {
    outline: none;
  }
}

.textareaTextColor {
  color: var(--text-color) !important;
}

.focused {
  border-color: var(--azurite);
}
