@import "@/styles/shared";
/* stylelint-disable-next-line no-empty-source */
.TextAndImageHeroWithBreadcrumbs {
  padding: 0 var(--page-gutter);
}

.inner {
  max-width: var(--section-max-width);
  margin: 0 auto;
}

.grid {
  @include grid;
  width: 100%;
}

.textSide {
  grid-column: 1/-1;
  order: 2;

  @include bp(tablet) {
    padding-right: calc(var(--gutter) * 2);
    order: 2;
    grid-column: 1/7;
  }
}

.breadcrumbs {
  margin-bottom: px(24);

  @include bp(tablet) {
    margin-bottom: px(43);
  }
}

.title {
  @include font-h4;
  margin-bottom: px(16);
}

.subtitle {
  @include font-sh4;
  margin: px(16) 0;
}

.description {
  @include font-body-l;
  margin-top: px(24);

  @include bp(tablet) {
    margin-top: px(40);
  }
}

.button {
  margin-top: px(48);

  @include bp(tablet) {
    margin-top: px(64);
  }
}

.imageSide {
  grid-column: 1/-1;
  order: 1;

  @include bp(tablet) {
    order: 2;
    grid-column: 7/-1;
  }
}

.imageContainer {
  [data-media-style='roundedCorners'] & {
    @include box-styling(roundedCorners);
  }

  [data-media-style='square'] & {
    @include box-styling(square);
  }

  [data-media-style='arch'] & {
    @include box-styling(arch);
  }
}

.featuredImage {
  @include position-100(absolute);
  object-fit: cover;
}

.staggeredImages {
  margin-top: var(--section-spacing);
}
