@import "@/styles/shared";
.TextAndImage {
  position: relative;
  padding: 0 var(--page-gutter);
}

.inner {
  @include grid;
  max-width: var(--section-max-width);
  margin: 0 auto;
  grid-gap: px(32) 0;

  @include bp(tablet) {
    grid-gap: 0 px(48);
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-column: 1 / -1;
  gap: px(32);
  order: 2;

  @include bp(tablet) {
    gap: px(40);
    flex: 1;
    grid-column: 1/7;
    order: 1;

    [data-text-position='right'] & {
      order: 2;
      grid-column: 7/-1;
    }
  }
}

.titleContainer {
  display: flex;
  flex-direction: column;
  gap: px(12);
  white-space: pre-line;

  @include bp(tablet) {
    gap: px(24);
  }
}

.eyebrow {
  @include font-handle;
}

.textAndImageTitle {
  @include font-h5;
}

.richTextLink {
  text-decoration: underline;
}

.descriptionText {
  @include font-body;
  margin-bottom: px(16);

  @include bp(tablet) {
    margin-bottom: px(8);
  }
}

.descriptionUnorderedList {
  padding-left: 1em;
}

.descriptionListElement {
  list-style: disc;
}

.mediaContainer {
  grid-column: -1/1;
  order: 1;

  @include bp(tablet) {
    grid-column: 7/-1;
    order: 2;

    [data-text-position='right'] & {
      order: 1;
      grid-column: -7/1;
    }
  }
}

.mediaContainerInner {
  position: relative;

  .arch & {
    @include box-styling(arch);
  }

  .roundedCorners & {
    @include box-styling(roundedCorners);
  }

  .square & {
    @include box-styling(square);
  }

  .originalDimensions & {
    aspect-ratio: var(--aspect-ratio);
  }
}

.image {
  @include position-100(absolute);
  object-fit: cover;
}
