@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.TimelineDatesCarousel {
  margin-top: px(70);

  @include bp(tablet) {
    margin-top: px(50);
  }
}

.carouselWrapper {
  width: px(130);
  margin: 0 auto;
  position: relative;

  @include bp(tablet) {
    width: px(250);
  }
}

.iconUp {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
  width: px(24);
  z-index: 3;

  @include bp(tablet) {
    width: px(38);
  }
}

.activeYearShape {
  @include box-styling(arch);
  background-color: var(--flour);
  aspect-ratio: 212/180;
  left: 0;
  top: 0;
  width: 100%;
  position: absolute;
  z-index: 1;
  transform: translateY(-35%);
}

.carouselInner {
  position: relative;
  z-index: 2;
}

.carouselItem {
  @include flex-center;
  text-align: center;
  user-select: none;

  &.active {
    color: var(--red);
  }
}

.year {
  @include reset-button;
  @include font-h5;
  transition: color $transition-short;
}
