@import "@/styles/shared";
.FeaturedTextBlock {
  position: relative;
  padding: var(--spacing-xl) var(--page-gutter);
  background: var(--section-background, transparent);
  overflow: hidden;
}

.backgroundImage {
  position: absolute;
  inset: 0;
  z-index: 0;

  .bgImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.container {
  position: relative;
  z-index: 1;
  max-width: var(--narrow-section-max-width);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: px(48);

  @include bp(laptop, true) {
    max-width: 100vw;
    padding: 0 px(24);
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: px(40);

  @include bp(tablet, true) {
    grid-column: 1 / 7;
    max-width: 100vw;
    padding: 0 px(24);
  
  }
}

.titleWrapper {
  @include font-h5;
  text-align: center;
  margin-bottom: px(24);

  color: var(--title-color, var(--color-text));

}

.textContent {
  display: block;
  @include font-body;
  text-align: center;

  p {
    margin-bottom: px(16);

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    list-style: disc;
    margin-left: px(24);
    margin-bottom: px(16);
  }
}

.ctaContainer {
  display: flex;
  justify-content: center;
  gap: px(24);

  @include bp(mobile, true) {
    flex-direction: column;
  }
}

.imageContainer {
  @include bp(tablet) {
    grid-column: 7 / -1;
  }
}

.image {
  width: 100%;
  height: auto;
  border-radius: var(--radius-md);
}