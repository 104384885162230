@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.LocationHeroHours {
  --border-color: rgba(#{$black-rgb}, 0.1);
  --holiday-color: #{$red};
  margin: px(16) 0;
  padding: px(16) 0;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);

  @include bp(tablet) {
    margin: px(24) 0;
    padding: px(24) 0;
  }
}

.openClosedBlurb {
  margin-bottom: px(16);
}

.dayHoursList__item {
  display: flex;
  justify-content: space-between;
}

.dayInfo,
.hoursInfo {
  display: flex;
  flex-direction: column;
}

.dayHoursList__item__day {
  @include font-detail;
  display: flex;
  align-items: center;
}

.dayAsterisk {
  color: var(--holiday-color);
  margin-left: px(2);
}

.holidayName {
  @include font-detail;
  text-transform: none;
  color: var(--holiday-color);
  margin-top: px(-2);
  margin-bottom: px(8);
  font-size: px(14);
  
  @include bp(tablet) {
    font-size: px(14); 
  }
}

.dayHoursList__item__hours {
  @include font-detail;
  text-align: right;
}

.holidayHours {
  @include font-detail;
  color: var(--holiday-color);
  text-align: right;
  font-size: px(14);
  margin-top: px(-2);
  margin-bottom: px(8);

  @include bp(tablet) {
    font-size: px(14);
  }
}

.holidayHoursContainer {
  margin-top: px(24);
  border-top: 1px solid var(--border-color);
  padding-top: px(16);
}

.titleButton {
  @include reset-button;
  display: flex;
  align-items: center;
  margin-bottom: px(16);
}

.titleButton__text {
  @include font-body-s;
  margin-right: px(5);
}

.titleButton__iconContainer {
  width: px(20);
  transform: translateY(#{px(-2)});

  [data-holidays-opened='true'] & {
    transform: rotate(180deg);
  }
}

.holidayHoursList__item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: px(8);
}

.holidayHoursList__itemTitle {
  @include font-detail;
  color: inherig;
}

.holidayHoursList__itemHours {
  @include font-detail;
  text-align: right;
  color: inherit;
}
