@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.AccordionItemsWithSideNavigation {
  padding: 0 var(--page-gutter);

  --top-padding: calc(var(--header-height) + #{px(20)});
}

.inner {
  max-width: var(--section-max-width);
  margin: 0 auto;
}

.listItem {
  margin-top: calc(var(--header-height) * -1);

  @include bp(laptop) {
    margin-top: 0;
  }

  &:not(:first-child) {
    padding-top: calc(var(--header-height) + var(--content-with-sticky-nav-button-height) + #{px(20)});

    @include bp(laptop) {
      padding-top: calc(var(--header-height) + #{px(20)});
    }
  }

  &:first-child {
    margin-top: calc(var(--header-height) * -2);

    @include bp(tablet) {
      margin-top: 0;
    }

    @include bp(laptop, true) {
      padding-top: calc(var(--header-height) + var(--content-with-sticky-nav-button-height) + #{px(20)});
    }
  }
}

.listItem__title {
  @include font-sh3;
  margin-bottom: px(48);
}

.accordion {
  margin-bottom: 0;

  [data-accordion-title] {
    @include font-body-xl;
  }

  [data-accordion-item] {
    @include bp(tablet, true) {
      padding-bottom: px(16);
      margin-bottom: px(16);
    }
  }
}

.rta {
  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
}

.richTextLink {
  text-decoration: underline;
}

.descriptionText {
  @include font-body;
  margin-bottom: px(24);
}

.descriptionUnorderedList {
  padding-left: 1em;
}

.descriptionListElement {
  list-style: disc;
}
