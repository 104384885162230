@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.EventDetails {
  padding: 0 var(--page-gutter);
}

.inner {
  @include grid;
  max-width: var(--section-max-width);
  margin: 0 auto;
}

.textSide {
  grid-column: 1/-1;
  order: 2;

  @include bp(tablet) {
    order: 1;
    grid-column: 1/7;
    padding-right: var(--gutter);
  }
}

.title {
  @include font-h3;
  margin-bottom: px(16);

  @include bp(tablet) {
    margin-bottom: px(24);
  }
}

.subtitle {
  @include font-detail-l-display;
  display: flex;
  align-items: center;
  gap: px(8);
}

.subtitle__asterisk {
  @include red-asterisk;
}

.description {
  @include font-sh4;
  margin-top: px(56);
  margin-bottom: 16px;
}

.eventDetailsContainer {
  margin-top: px(32);
  padding: px(24) 0;
  border-top: 1px solid rgba(var(--fg-rgb), 0.1);
  border-bottom: 1px solid rgba(var(--fg-rgb), 0.1);

  @include bp(tablet) {
    margin: px(48) 0;
  }
}

.eventDetailsTitle {
  @include font-body-s;
  display: flex;
  gap: px(6);
  align-items: center;
}

.eventDetailsTitle__dot {
  @include box(px(8));
  border-radius: 50%;
  background-color: var(--jade-green);
}

.eventDetailsText {
  @include font-body-l;
  white-space: pre-line;
  margin-top: px(16);
}

.inviteCodeContainer {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid rgba(var(--fg-rgb), 0.1);
  border-radius: 1000px;
  padding: px(8);
}

.inviteCodeContainer__input {
  @include reset-button;
  @include font-body-xl;
  height: var(--button-height);
  flex: 1;
  padding: 0 px(32);

  ::placeholder {
    color: var(--input-placeholder-color);
  }
}

.error {
  @include font-body;
  color: var(--red);
  margin-top: px(10);
}

.ctaWrapper {
  margin-top: 32px;
  display: flex;
  gap: 1.6rem;

  @include bp(mobile){
    margin-top: 48px;
  }

}

.imageSide {
  grid-column: 1/-1;
  order: 1;
  
  @include bp(tablet) {
    order: 2;
    grid-column: 7/-1;
    padding-left: var(--gutter);
    padding-right: var(--gutter);
  }
}

.imageContainer {
  @include box-styling(roundedCorners);
}

.image {
  @include position-100(absolute);
}
